<template>
  <v-layout column>
    <v-form ref="detailsForm" class="mt-6">
      <PatientForm
        v-if="patient"
        :value.sync="patient"
        syncId="patientData"
        autosave
      ></PatientForm>
    </v-form>
    <v-layout :class="$vuetify.breakpoint.xsOnly && 'justify-center'">
      <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
      <DefaultButton
        class="mx-4 px-0 mb-6"
        @click.prevent="synchronize"
        primary
        >Zapisz</DefaultButton
      >
    </v-layout>
    <ConfirmDialog
      v-if="showDialog"
      :value.sync="showDialog"
      :positiveAction="deletePatient"
      positiveButtonText="Archiwizuj"
      title="Archiwizuj pacjenta"
      text="Czy na pewno chcesz zarchiwizować tego pacjenta?"
    ></ConfirmDialog>
  </v-layout>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import PatientService from "@/services/patient.service";
import AutosaveMixin from "@/mixins/AutosaveMixin";
import DoctorReloadMixin from "@/mixins/DoctorReloadMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [ProxyCRUDMixin, AutosaveMixin, DoctorReloadMixin],
  props: {
    value: {}
  },
  data() {
    return {
      collapseButton: false,
      hadPesel: null,
      hadAddress: null,
      banReason: "",
      banUnbanPatientPopupVisible: false,
      showDialog: false,
      syncId: "patientData",
      time: 4500,
    };
  },
  computed: {
    patient: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:patient", value);
      }
    },
    hasPesel() {
      return this.patient && !!this.patient.pesel;
    },
    hasAddress() {
      return this.patient && !!this.patient.address;
    },
    banUnbanButtonText() {
      if (this.patient) {
        return !this.patient.banned ? "Zablokuj" : "Odblokuj";
      } else {
        return "";
      }
    },
  },
  components: {
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    PatientForm: () => import("@/components/forms/PatientForm"),
    MoreCardActionButton: () =>
      import("@/components/buttons/MoreCardActionButton"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
  methods: {
    fetch() {
      this.beforeRequest();
      PatientService.getPatient().then((x) => {
          this.patient = x;
          if (this.hadPesel == null || this.hadAddress == null) {
            this.hadPesel = !!this.patient.pesel;
            this.hadAddress = !!this.patient.address;
          }
          this.reset();
        })
        .catch((x) => this.handleError(x));
    },
    synchronize(autosave) {
      if (!this.$refs.detailsForm.validate()) {
        this.notModified();
        this.handleError("Popraw pola zaznaczone na czerwono");
        return;
      }
      this.beforeRequest();
      this.syncing();
      PatientService.editPatient(this.patient)
        .then((x) => {
          if (!autosave || this.isSyncing) {
            this.patient = x;
            if (this.hasPesel) {
              this.hadPesel = true;
            } else {
              this.hadPesel = false;
            }
            if (this.hasAddress) {
              this.hadAddress = true;
            } else {
              this.hadAddress = false;
            }
            this.synced();
            this.handleSuccess("Wprowadzone zmiany zostały zapisane");
          } else {
            this.reset();
          }
        })
        .catch((x) => this.handleError(x));
    },
    banUnbanPatient() {
      this.beforeRequest();
      if (!this.patient.banned) {
        PatientService.banPatient(this.patient, this.banReason)
          .then(() => {
            this.handleSuccess("Zablokowano pacjenta");
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
      if (this.patient.banned) {
        PatientService.unbanPatient(this.patient)
          .then(() => {
            this.handleSuccess("Odblokowano pacjenta");
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    banReasonUpdate(banReason) {
      this.banReason = banReason;
    },
    showConfirmDialog() {
      this.showDialog = true;
    },
    reloadData() {
      this.fetch();
    },
  }
};
</script>